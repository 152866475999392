var app;
(function (app) {
    var layout;
    (function (layout) {
        'use strict';
        var LayoutController = /** @class */ (function () {
            /* @ngInject */
            LayoutController.$inject = ["$state", "$location", "$window", "mylocalStore", "$sce", "rxData", "$rootScope", "dataservice", "$timeout", "$scope", "$element"];
            function LayoutController($state, $location, $window, mylocalStore, $sce, rxData, $rootScope, dataservice, $timeout, $scope, $element) {
                var _this = this;
                this.$state = $state;
                this.$location = $location;
                this.$window = $window;
                this.mylocalStore = mylocalStore;
                this.$sce = $sce;
                this.rxData = rxData;
                this.$rootScope = $rootScope;
                this.dataservice = dataservice;
                this.$timeout = $timeout;
                this.$scope = $scope;
                this.$element = $element;
                $rootScope.changeLanguage = function (lang, reset) {
                    if (reset === void 0) { reset = false; }
                    _this.changeLanguage(lang, reset);
                };
                this.loadOnDomready();
            }
            LayoutController.prototype.forceClick = function (url) {
                console.log('forceClick', url);
                this.$location.path(url);
            };
            LayoutController.prototype.changeLanguage = function (lang, reset) {
                if (reset === void 0) { reset = false; }
                if (lang === "TH" || lang === "ENG") {
                    LANG_TARGET = this.mylocalStore.setLanguage(lang, reset);
                    console.log('clicked!!', lang);
                }
                else {
                    LANG_TARGET = this.mylocalStore.setLanguage("ENG", reset);
                }
                this.$window.location.reload();
                console.log('language set to> ', LANG_TARGET);
            };
            LayoutController.prototype._HTML = function (html) {
                return this.$sce.trustAsHtml(html);
            };
            LayoutController.prototype.loadOnDomready = function () {
                var _this = this;
                angular.element(document).ready(function () {
                    _this.adjustImageSize();
                });
                // to be sure
                this.$rootScope.$on('$viewContentLoaded', function () {
                    _this.$timeout(function () {
                        _this.adjustImageSize();
                    }, 200);
                });
            };
            // set half size attr for non-ratina
            LayoutController.prototype.adjustImageSize = function () {
                var _this = this;
                var imgs = this.$element.find('img');
                if (imgs) {
                    $.each(imgs, function (index, value) {
                        var w = $(value).attr('width');
                        if (w) {
                            var hw = Number(w) / 2;
                            if (_this.isRetina()) {
                                $(value).css('maxWidth', w + 'px');
                            }
                            else {
                                // show half width
                                console.log('our half wd is', hw);
                                //$(value).attr('width', hw);
                                $(value).css('maxWidth', hw + 'px');
                            }
                        }
                    });
                }
            };
            LayoutController.prototype.isRetina = function () {
                function isHighDensity() {
                    return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 1.3));
                }
                function retina() {
                    return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)').matches)) || (window.devicePixelRatio && window.devicePixelRatio >= 2)) && /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
                }
                return isHighDensity() || retina();
            };
            return LayoutController;
        }());
        layout.LayoutController = LayoutController;
        angular
            .module('app.layout', []);
        angular
            .module('app.layout')
            .controller('layoutController', LayoutController);
    })(layout = app.layout || (app.layout = {}));
})(app || (app = {}));
